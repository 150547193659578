<template>
  <div class="product-container">
    <!-- banner_cp -->
    <div class="banner_cp" style="background-color: #f6f6f6">
      <div class="margin width1480 hidden">
        <div class="l">
          <dl style="display: block">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_shz1.png"
              class="shou-wang-zhe-temp-img"
            />
          </dl>
          <dl>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_shz2.png"
              class="shou-wang-zhe-temp-img"
            />
          </dl>
          <dl>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_shz3.png"
              class="shou-wang-zhe-temp-img"
            />
          </dl>
        </div>
        <div class="r">
          <div class="bg a-fdB">
            <dd>守 望 者</dd>
            <dl>
              <p>车规级别品控 | 远程智能操控 | 人性侧插设计</p>
            </dl>
            <ol class="f">
              <dt class="o">
                <span><i style="background-color: #141414"></i></span>
                <p>极夜黑</p>
              </dt>
              <dt>
                <span><i style="background-color: #909090"></i></span>
                <p>皓月银</p>
              </dt>
              <dt>
                <span><i style="background-color: #c5889f"></i></span>
                <p>落樱粉</p>
              </dt>
            </ol>
            <a @click="$U.openProductFile('shouwangzhe')">产品手册</a>
          </div>
        </div>
      </div>
    </div>
    <!-- guardian_jcxl -->
    <div id="g1">
      <div class="guardian_jcxl">
        <div class="margin width1480 hidden">
          <div class="t">
            <dd class="cp_bt_y">家充系列</dd>
            <dl class="cp_bt_d">人性化侧插枪设计 让充电很简单</dl>
            <dd class="cp_bt_y">车规级打造 安全充电</dd>
          </div>
          <div class="f">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_jcxl_bg.png"
              class="bg"
            />
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_jcxl.png"
              class="i"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- guardian_jskh_bg -->
    <div id="g2">
      <div
        class="guardian_jskh_bg"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_jskh_bg.jpg);
        "
      >
        <i
          class="bg"
          style="
            background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_jskh_sd.png);
          "
        ></i>
        <div
          class="margin width1480 hidden"
          style="
            background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_jskh.png);
          "
        >
          <div class="t">
            <dd>10项技术考核 IP55等级防护</dd>
            <dl>采用PCB三防喷漆，防尘、防雾、防雷电</dl>
          </div>
        </div>
      </div>
    </div>
    <!-- cp_aqfh -->
    <div id="g3">
      <div class="margin width1480 hidden">
        <ul class="cp_aqfh">
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh1.png"
                />
              </ol>
              <dd>过压/欠压保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh2.png"
                />
              </ol>
              <dd>过流保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh3.png"
                />
              </ol>
              <dd>雷电保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh4.png"
                />
              </ol>
              <dd>接地保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh5.png"
                />
              </ol>
              <dd>防漏电保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh6.png"
                />
              </ol>
              <dd>短路保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh7.png"
                />
              </ol>
              <dd>环境电源异常数据记录</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/cp_aqfh8.png"
                />
              </ol>
              <dd>IP55</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh9.png"
                />
              </ol>
              <dd>过温保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh10.png"
                />
              </ol>
              <dd>预约充电</dd>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- guardian_bccs -->
    <div id="g4">
      <div
        class="guardian_bccs"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_bccs_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t cp_bt_d">10000+次拔插测试 经久耐用</div>
          <div class="f"><span id="sj">100000</span><i>+</i></div>
        </div>
        <img
          src="https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_bccs.png"
          class="tp"
        />
      </div>
    </div>
    <!-- guardian_znjh -->
    <div id="g5">
      <div class="guardian_znjh_tit">
        <dd class="cp_bt_d">智能交互 随心来电</dd>
        <dl class="cp_bt_y">三种充电模式</dl>
      </div>
      <div
        class="guardian_znjh"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_znjh_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_znjh.png"
            class="tp"
          />
          <div class="r">
            <dd class="cp_bt_d">车主识别防止盗充</dd>
            <dl class="cp_bt_y">配备防盗功能，匹配唯一身份识别智充卡启动</dl>
          </div>
        </div>
      </div>
    </div>
    <!-- guardian_zyapp -->
    <div id="g6">
      <div
        class="guardian_zyapp"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_zyapp_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="l cp_bt_d">专属APP 智慧体验</div>
          <ul class="r">
            <li>
              <dd>预约充电</dd>
              <dl>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_zyapp1.png"
                />
              </dl>
            </li>
            <li>
              <dd>专属APP</dd>
              <dl>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/%E5%A4%84%E7%90%86%E5%88%98%E6%B5%B71.png"
                />
              </dl>
            </li>
            <li>
              <dd>充电中</dd>
              <dl>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/%E5%A4%84%E7%90%86%E5%88%98%E6%B5%B72.png"
                />
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- guardian_rxhsj -->
    <div id="g7">
      <div
        class="guardian_rxhsj"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_rxhsj_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_rxhsj_l.png"
            class="l"
          />
          <div class="r">
            <dd class="cp_bt_d">人性化设计 懂你所想</dd>
            <dl class="cp_bt_y">380V、220V 两款功率选择</dl>
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_rxhsj_r.png"
              />
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- guardian_wlfg -->
    <div id="g8">
      <div
        class="cp_f_bg cp_f_bg_full"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_wlfg_bg.jpg);
        "
      >
        <!-- style="background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_wlfg_f_bg.png);" -->
        <div class="margin width1480 hidden">
          <div class="t">
            <dd>智享生活 守望生活</dd>
            <dl>
              安装网络覆盖全国360个城市,已为100万户家庭生产并安装了家庭充电桩！
            </dl>
            <ol>
              <a @click="$U.openProductFile('shouwangzhe')"
                >产品手册<i class="fa fa-angle-right"></i
              ></a>
            </ol>
          </div>
          <!-- <div class="f">
              <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/guardian_wlfg.png" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
export default {
  name: "shouwangzhe",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();
    onMounted(() => {
      document.title = "守望者";
      // 滚动到页面最上方
      window.scrollTo(0, 0);
      var options = {
        useEasing: true,
        useGrouping: true,
        separator: "",
        decimal: "",
        prefix: "",
        suffix: "",
      };
      var demo = new CountUp("sj", 0, 10000, 0, 1, options);
      $(".banner_cp .width1480 .r ol.f dt").click(function () {
        var liindex = $(".banner_cp .width1480 .r ol.f dt").index(this);
        $(this).addClass("o").siblings().removeClass("o");
        $(".banner_cp .width1480 .l dl")
          .eq(liindex)
          .fadeIn(150)
          .siblings("dl")
          .hide();
      });
      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        for (var i = 1; i < 20; i++) {
          if ($("#g" + i) && $("#g" + i).offset()) {
            //加循环
            if ($("#g" + i).offset().top <= wst) {
              //判断滚动条位置
              $("#g" + i).addClass("on"); //给当前导航加c类
            } else {
              //$("#p"+i).removeClass('on');
            }
            //#g4
            if ($("#g4").offset().top <= wst) {
              demo.start();
            }
          }
        }
      });
    });
    return {};
  },
};
</script>

<style lang="less" scoped>
img.shou-wang-zhe-temp-img {
  right: -40px !important;
}
</style>
